import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ContactService} from '../shared/services/contact.service';
import {Router} from '@angular/router';
import {EmailValidators} from './email.validators';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  form;
  clicked;
  status;
  response;
  data;
  constructor(fb: FormBuilder, private contactService: ContactService, private router: Router) {
    this.form = fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.pattern('[^ @]*@[^ @]*'), EmailValidators.cannotContainSpace],
      ],
      phone_number: ['', [Validators.required, EmailValidators.cannotContainSpace],
      ],
      message: ['', [
        Validators.required,
        Validators.minLength(3)]]
    });
  }

  ngOnInit(): void {
  }

  connect(): any {
    this.clicked = true;
    // console.log(this.form.value);
    this.contactService.connect(this.form.value).subscribe(
      data => {
        this.data = data;
        this.status = this.data.status;
        this.response = this.data.message;
        this.clicked = false;
        this.message.reset();
        this.form.reset();
      },
      (error: Response) => {
        console.log(error);
        this.clicked = false;
      }
    );
  }


  get name(): any {
    return this.form.get('name');
  }
  get email(): any {
    return this.form.get('email');
  }
  get phone_number(): any {
    return this.form.get('phone_number');
  }
  get message(): any {
    return this.form.get('message');
  }

}
