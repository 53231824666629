<app-topbar></app-topbar>
<div role="main" class="main" style="		background: url(assets/img/control/background-body.svg) center center repeat;">
  <section class="section section-concept section-no-border section-light p-0 m-0" id="section-concept">
    <div class="cover" style="">
      <div class="container">
        <div class="row align-items-center mt-0 pt-lg-5">
          <div class="col-lg-8  col-sm-12 text-left caption mt-5">
            <h1 style="color: #FFFFFF;"
              class=" line-height-2 mb-3 appear-animation  appear-animation-visible font-weight-bold "
              data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400"
              data-appear-animation-duration="750">
              We pride ourselves<br>
              on delivering the highest<br>
              quality in everything we do.
            </h1>

          </div>
        </div>
      </div>
    </div>

  </section>


  <section class="section section-no-border  bg-color-light-scale-1 p-0 m-0">
    <div class="container py-lg-5">

      <div class="bg-blue my-lg-5 mx-lg-5">
        <div class="row align-items-center text-left">
          <div class="col-md-6 pb-1">
            <img class="img-fluid about-image" src="assets/img/control/about-image.png" alt="">
          </div>
          <div class="col-md-6 pb-1 who">
            <h2
              class="font-weight-bold text-white py-3 mb-0 appear-animation animated fadeInUpShorter appear-animation-visible"
              data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200"
              data-appear-animation-duration="750" style="animation-delay: 200ms;">
              Who We Are</h2>
            <p class="mb-0 appear-animation animated maskUp appear-animation-visible text-white"
              data-appear-animation="maskUp" data-appear-animation-delay="400" style="animation-delay: 400ms;">
              Speedup Diverse Synergy Limited is a Marine company that provides wide range of industry specific
              services. Speedup Diverse Synergy is an organisation that conforms with international best practices with
              a very strong presence in the gulf of Guinea
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-no-border bg-color-light-scale-1 pb-lg-5 pt-0 m-0" style="overflow: hidden;">
    <div
      class="container featured-boxes featured-boxes-style-7 bg-blue text-white mission  pt-lg-5 pb-lg-5 text-center">
      <div class="row text-left  ">

        <div class="col-lg-6  py-5  px-5">
          <div class="featured-box featured-box-secondary featured-box-effect-7  d-flex">
            <div class="box-content text-left">
              <h4 class="font-weight-extra-bold text-9 mt-3 text-white  mb-3" data-to="225">Mission</h4>
              <p class="mb-0 text-white"> To provide swift maritime security solutions and other marine services while
                conforming to best practices.
              </p>
            </div>
            <div class="box-content text-left counter">
              <h4 class="font-weight-extra-bold text-9 mt-3 text-white mb-3" data-to="3167">Vision</h4>
              <p class="mb-0 text-white"> To be the leading Private Security Company (PMSC) across the Gulf of Guinea
                and beyond</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 bg-cream divider-left-border py-5 px-5">
          <div class="featured-box  featured-box-tertiary featured-box-effect-7 ">
            <p class="box-content text-left counter">
            <h4 class="font-weight-extra-bold text-9 mt-3 text-white mb-3" data-to="3167">Our Core Values</h4>
            <!--              <p  class="mb-0 text-white"> To be the leading Private Security Company (PMSC) across the Gulf of Guinea and beyond</p>-->
            <div class="row">
              <div class="col-lg-6">
                <p class="text-white text-left" style="font-size: 14px;">
                  <i class="icon mark-green mr-2"></i> Integrity<br>
                  <i class="icon mark-green mr-2"></i> Reliability<br>
                  <i class="icon mark-green mr-2"></i> Speed<br>
                  <i class="icon mark-green mr-2"></i> Quality<br>
                </p>
              </div>
              <div class="col-lg-6">
                <p class="text-white  text-left" style="font-size: 14px;">
                  <i class="icon mark-green mr-2"></i> Honesty<br>
                  <i class="icon mark-green mr-2"></i> Commitment<br>
                  <i class="icon mark-green mr-2"></i> Confidence<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="section section-no-border bg-color-light-scale-1 p-0 m-0" style="overflow: hidden;">
    <div class="container py-2">
      <div class="row">
        <div class="col-lg-3">
          <h2
            class="why-heading font-weight-bold py-3 text-10 mb-0 appear-animation animated fadeInUpShorter appear-animation-visible"
            data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200"
            data-appear-animation-duration="750" style="animation-delay: 200ms;">
            Why<br>
            Choose Us?
          </h2>
        </div>
        <div class="col-lg-9">

          <div class="row process process-vertical">
            <div
              class="process-step col-md-6 col-lg-4 appear-animation animated fadeInUpShorter appear-animation-visible"
              data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200" style="animation-delay: 200ms;">
              <div class="process-step-circle">
                <strong class="process-step-circle-content">01</strong>
              </div>
              <div class="process-step-content">
                <h4 class="mb-0 text-4 font-weight-bold text-blue">Reliable</h4>
                <p class="mb-0">
                  We maintain the highest standard across all our operations, and you can always count on us to meet all
                  your oil and marine needs.
                </p>
              </div>
            </div>
            <div
              class="process-step col-md-6 col-lg-4 appear-animation animated fadeInUpShorter appear-animation-visible"
              data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400" style="animation-delay: 400ms;">
              <div class="process-step-circle">
                <strong class="process-step-circle-content">02</strong>
              </div>
              <div class="process-step-content">
                <h4 class="mb-0 text-4 font-weight-bold text-blue">Trusted</h4>
                <p class="mb-0">We are trusted by Integrated Oil Companies at home and abroad.
                </p>
              </div>
            </div>
            <div
              class="process-step col-md-6 col-lg-4 appear-animation animated fadeInUpShorter appear-animation-visible"
              data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600" style="animation-delay: 600ms;">
              <div class="process-step-circle">
                <strong class="process-step-circle-content">03</strong>
              </div>
              <div class="process-step-content">
                <h4 class="mb-0 text-4 font-weight-bold text-blue">Safe</h4>
                <p class="mb-0">Security is key in all of our operations. We would never jeopardize your security, or
                  the safety of our crew, for any reason.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>

  <section class="section section-no-border  bg-color-light-scale-1 m-0" id="services">
    <div class="container py-lg-5">

      <div class="row text-left">
        <div class="col-md-3 pb-1 service">
          <h2 class="font-weight-bold py-3 mb-0 appear-animation animated fadeInUpShorter appear-animation-visible"
            data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200"
            data-appear-animation-duration="750" style="animation-delay: 200ms;">
            Our Services
          </h2>
          <p class="mb-0 appear-animation animated maskUp appear-animation-visible" data-appear-animation="maskUp"
            data-appear-animation-delay="400" style="animation-delay: 400ms;">
            We provide different services in the marine industry
          </p>
        </div>
        <div class="col-md-8 offset-lg-1 pb-1">
          <div class="row">
            <div class="col-md-6 pb-1">
              <div class="portfolio-item">
                <span class="thumb-info thumb-info-lighten border-radius-0">
                  <span class="thumb-info-wrapper border-radius-0">
                    <img src="assets/img/control/mvkhajan.png" class="img-fluid border-radius-0" alt="">
                    <span class="thumb-info-title">
                      <span class="thumb-info-inner">MV Khajan</span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
             <div class="col-md-6 pb-1">
              <div class="portfolio-item">
                <span class="thumb-info thumb-info-lighten border-radius-0">
                  <span class="thumb-info-wrapper border-radius-0">
                    <img src="assets/img/control/mvmeedoi.png" class="img-fluid border-radius-0" alt="">
                    <span class="thumb-info-title">
                      <span class="thumb-info-inner">MV Meedoi</span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
             <div class="col-md-6 pb-1">
              <div class="portfolio-item">
                <span class="thumb-info thumb-info-lighten border-radius-0">
                  <span class="thumb-info-wrapper border-radius-0">
                    <img src="assets/img/control/mvshalom1.png" class="img-fluid border-radius-0" alt="">
                    <span class="thumb-info-title">
                      <span class="thumb-info-inner">MV Shalom 1</span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
             <div class="col-md-6 pb-1">
              <div class="portfolio-item">
                <span class="thumb-info thumb-info-lighten border-radius-0">
                  <span class="thumb-info-wrapper border-radius-0">
                    <img src="assets/img/control/mvtuguemi.png" class="img-fluid border-radius-0" alt="">
                    <span class="thumb-info-title">
                      <span class="thumb-info-inner">MV Tuguemi</span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>




</div>
<app-bottombar></app-bottombar>