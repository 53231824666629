<footer id="footer">
  <div class="container">

    <div class="row py-5 my-lg-4">
      <div class="col-lg-5 mb-4 mb-lg-0 text-white">
        <a routerLink="/" class="logo pr-0 pr-lg-3 logo-footer">
          <img alt="Eagle club Logo" src="assets/img/control/logo-footer.svg" height="80px">
        </a>
        <p class="mt-3 text-white text-5 font-weight-bold">RC No.: 1624318</p>
        <p class="text-white text-5 font-weight-bold">IMO No.: 6269997</p>
      </div>

      <div class="col-lg-3 mb-4 mb-md-0 text-white">
        <div class="contact-details">
          <h4 class="text-3 mb-lg-3 font-weight-bold">Contact</h4>
          <p>enquiries@sdsynergy.co</p>
          <p>+234 80 3677 9010</p>

        </div>
      </div>


      <div class="col-md-4 col-lg-4 text-white">
        <div class="contact-details">
          <h4 class="text-3 mb-3 font-weight-bold">Address</h4>
          <p>14A Oba Elegushi Road, Ikoyi,
            Lagos Nigeria</p>
          <p>No 6 Isaiah Odolu street,GRA phase 2
            Portharcourt , Rivers State</p>
          <p>No. 10 Item Street, Ikenegbu, Owerri
            (Formerly Plot 72 Ikenegbu Layout)</p>
          <p>Unit 25
            Cygnus Business Center
            Dalmeyer road
            Nw10 2Xa</p>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-copyright">
    <div class="container py-2">
      <div class="row py-4">
        <div class="col-lg-6 offset-lg-2 d-flex align-items-center justify-content-center justify-content-lg-end">
          <p>Copyright Speedup Diverse Synergy. 2021 All Right Reserved</p>
        </div>
      </div>
    </div>
  </div>
</footer>