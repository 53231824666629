import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

  constructor(private router: Router)  { }

  ngOnInit(): void {
  }

  gotodiv(id): void {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({behavior: 'smooth', block: 'start'});
    } else {
      this.router.navigate(['/']);
      const newElement = document.getElementById(id);
      newElement.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }

}
