<app-topbar></app-topbar>
<div role="main" class="main">

  <section class="section section-no-border  bg-white m-0">
    <div class="container">

      <div class="row" style="display: flex; margin-top: 100px">
        <div class="col-md-4 bg-light-green">
          <div class="overflow-hidden mb-1">
            <h2 class="font-weight-normal text-green text-7 mb-0 appear-animation animated maskUp appear-animation-visible" data-appear-animation="maskUp" data-appear-animation-delay="200" style="animation-delay: 200ms;">
              <strong class="font-weight-bold">Hello!<br>
                Contact us
              </strong>
            </h2>
          </div>
          <div class="overflow-hidden mb-4 pb-3">
            <p class="mb-0 appear-animation animated maskUp appear-animation-visible" data-appear-animation="maskUp" data-appear-animation-delay="400" style="animation-delay: 400ms;">
              Say hello, write us feedback, make enquiries or give us a call.
            </p>
          </div>
          <div class="appear-animation animated fadeIn appear-animation-visible" data-appear-animation="fadeIn" data-appear-animation-delay="800" style="animation-delay: 800ms;">
            <p class="title text-green font-weight-bold">Contact</p>
            <p>enquiries@sdsynergy.co<br>+234 80 3677 9010</p>
          </div>
          <div class="appear-animation animated fadeIn appear-animation-visible" data-appear-animation="fadeIn" data-appear-animation-delay="800" style="animation-delay: 800ms;">
            <p class="title text-green font-weight-bold">Head Office:</p>
            <p>No. 10 Item Street, Ikenegbu, Owerri<br>
              (Formerly Plot 72 Ikenegbu Layout)</p>
          </div>
          <div class="appear-animation animated fadeIn appear-animation-visible" data-appear-animation="fadeIn" data-appear-animation-delay="800" style="animation-delay: 800ms;">
            <p class="title text-green font-weight-bold">Branch Offices:</p>
            <p>No 6 Isaiah Odolu street,<br>GRA phase 2 Portharcourt , Rivers State.</p>
            <p>No. 10 Item Street, Ikenegbu, Owerri<br>
              (Formerly Plot 72 Ikenegbu Layout)</p>
          </div>
        </div>
        <div class="col-md-7 offset-lg-1 pb-1" style="height: auto">



          <div class="row">
            <div class="col-lg-12 bg-blue">
              <div class="overflow-hidden mb-1">
                <h2 class="font-weight-normal contact-h text-7 text-white mb-0 appear-animation animated maskUp appear-animation-visible" data-appear-animation="maskUp" data-appear-animation-delay="200" style="animation-delay: 200ms;">
                  <strong class="font-weight-bold">Let's do this</strong> </h2>
              </div>
              <div class="overflow-hidden mb-4 pb-3">
                <p class="mb-0 contact-p text-white appear-animation animated maskUp appear-animation-visible" data-appear-animation="maskUp" data-appear-animation-delay="400" style="animation-delay: 400ms;">
                  Please feel free to leave us a message, or email us at <span class="text-green">enquireis@sdsynergy.co</span>, and we'll get back to you as soon as possible.
                </p>
                <p class="alert" [class.alert-danger]="!status" [class.alert-success]="status" *ngIf="response">{{response}}</p>

              </div>


              <form  [formGroup]="form" (ngSubmit)="connect()" id=""  class="contact-form text-blue" novalidate="novalidate">
                <div class="contact-form-success alert alert-success d-none mt-4" id="contactSuccess">
                  <strong>Success!</strong> Your message has been sent to us.
                </div>


                <div class="form-row">
                  <div class="form-group col-lg-6">
                    <input type="text" formControlName="name"  maxlength="100" class="form-control" name="fname"  id="fname"  placeholder="Full name *" required="">
                    <div *ngIf="name.touched && name.invalid" class="alert alert-danger error">
                      <div *ngIf="name.errors.required">Name is required</div>
                      <div *ngIf="name.errors.minlength">Name should be minimum of 3 characters</div>
                    </div>
                  </div>
                  <div class="form-group col-lg-6">
                    <input  type="email" formControlName="email"  placeholder="Your Email" maxlength="100" class="form-control" name="email" id="email" required="">
                    <div *ngIf="email.touched && email.invalid" class="alert alert-danger error">
                      <div *ngIf="email.errors.required">Email is required</div>
                      <div *ngIf="email.errors.pattern">Email is invalid</div>
                      <div *ngIf="email.errors.cannotContainSpace">Email cannot contain space</div>
                    </div>
                  </div>
                  <div class="form-group col-lg-12">
                    <input  type="email" formControlName="phone_number"  placeholder="Your phone (optional)" maxlength="100" class="form-control" name="phone" id="phone" required="">
                    <div *ngIf="phone_number.touched && phone_number.invalid" class="alert alert-danger error">
                      <div *ngIf="phone_number.errors.required">Phone Number is required</div>
                      <div *ngIf="phone_number.errors.pattern">Phone Number is invalid</div>
                      <div *ngIf="phone_number.errors.cannotContainSpace">Phone Number cannot contain space</div>
                    </div>
                  </div>
                  <div class="form-group col-lg-12">
                    <textarea maxlength="5000" formControlName="message"   rows="5" class="form-control" name="message" id="message" placeholder="Your message " required=""></textarea>
                    <div *ngIf="message.touched && message.invalid" class="alert alert-danger error">
                      <div *ngIf="message.errors.required">Message is required</div>
                      <div *ngIf="message.errors.minlength">Message should be minimum of 3 characters</div>
                    </div>
                  </div>
                  <div class="form-group col-lg-12">
                    <input type="submit" value="Send Message" class="btn btn-success font-weight-bold border-radius-0" style="padding: 6px 25px;">
                  </div>

                </div>


              </form>

            </div>
          </div>

        </div>

      </div>
    </div>
  </section>

</div>
<app-bottombar></app-bottombar>
