import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from '../home/home.component';
import {ContactComponent} from '../contact/contact.component';
import {TermsComponent} from '../terms/terms.component';
import {PrivacyComponent} from '../privacy/privacy.component';


const routes: Routes = [

  {
    path: '',
    component: HomeComponent,
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'contact', component: ContactComponent,
    runGuardsAndResolvers: 'always'}
  ];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {enableTracing: true, onSameUrlNavigation: 'reload'}), CommonModule
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})

export class RoutingModule { }

