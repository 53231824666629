<header id="header" class="header-gray" data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': false, 'stickyEnableOnMobile': true, 'stickyStartAt': 70, 'stickyChangeLogo': false, 'stickyHeaderContainerHeight': 70}">
  <div class="header-body border-top-0 bg-blue box-shadow-none" style="border-bottom: 0 !important;">
    <div class="header-container container">
      <div class="header-row">
        <div class="header-column">
          <div class="header-row">
            <div class="header-logo appear-animation appear-animation-visible" data-appear-animation="fadeIn" data-plugin-options="{'accY': 100}">
              <a routerLink="/" class="goto-top"><img alt="Porto" width="163" height="65" data-sticky-width="163" data-sticky-height="36" data-sticky-top="0" src="assets/img/control/logo.svg"></a>
            </div>
          </div>
        </div>
        <div class="header-column justify-content-end">
          <div class="header-row">
            <div class="header-nav header-nav-links header-nav-light-text header-nav-dropdowns-dark">
              <div class="header-nav-main header-nav-main-square header-nav-main-dropdown-no-borders header-nav-main-text-capitalize header-nav-main-text-size-2 header-nav-main-mobile-dark header-nav-main-effect-1 header-nav-main-sub-effect-1 appear-animation appear-animation-visible" data-appear-animation="fadeIn" data-plugin-options="{'accY': 100}">
                <nav class="collapse">
                  <ul class="nav nav-pills" id="mainNav">

                    <li class="dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                      <a class="dropdown-item dropdown-toggle" data-hash data-hash-offset="130" routerLink="/">
                        Home
                      </a>
                    </li>

                    <li class="dropdown">
                      <a class="dropdown-item dropdown-toggle" data-hash data-hash-offset="130" (click)="gotodiv('services')">
                        Services
                      </a>
                    </li>


                    <li class="dropdown">
                      <a class="dropdown-item dropdown-toggle" data-hash data-hash-offset="130" routerLink="/contact" routerLinkActive="active">
                        Contact-us
                      </a>
                    </li>



                  </ul>
                </nav>
              </div>
              <a class="appear-animation appear-animation-visible d-lg-none" style="color: #008BCC" data-appear-animation="fadeIn" data-plugin-options="{'accY': 100}" href="#" target="_blank">
                <i class="icon login mb-4 mt-4 mr-1"></i>
              </a>
              <button class="btn d-lg-none" data-toggle="collapse" data-target=".header-nav-main nav"><i class="fa fa-bars"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
